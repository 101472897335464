import React, { useState } from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Trans } from 'react-i18next';
import { visit } from 'unist-util-visit';
import { ValidationError, string } from 'yup';
import rehypeRaw from 'rehype-raw';
import showdown from 'showdown';
import { MENTIONS_REGEX } from '../../constants';
import { combineClassNames } from '../../utils/combineClassNames';
import ProfilePopover from '../profile/popover';
import * as usersSelector from '../../../modules/core/selectors/users';

// detects whether a string contains an email address, but not if the string is
// an email address, these would both match
// "...info@oneteam.io!!", "info@oneteam.io"
const EMAIL_REG_EXP = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

// see here for info valid characters in email addresses
// https://stackoverflow.com/questions/2049502/what-characters-are-allowed-in-an-email-address
const ALPHABET = 'QWERTYUIOPASDFGHJKLZXCVBNM';
const LOWER_CASE_APHABET = ALPHABET.toLowerCase();
const DIGITS = '1234567890';
const SPECIAL_CHARS = "!#$%&'*+-/=?^_`{|}~";
const VALID_LEFT_EMAIL_CHARS = ALPHABET + LOWER_CASE_APHABET + DIGITS + SPECIAL_CHARS;
const VALID_RIGHT_EMAIL_CHARS = ALPHABET + LOWER_CASE_APHABET + DIGITS;

const TEXT_NODE = 3;

const checkForText = (node) => {
  if (!node.children) return node;
  node.children = node.children.reduce((acc, n) => { // eslint-disable-line no-param-reassign
    if (n.type === 'text') {
      const fragments = n.value.split(MENTIONS_REGEX);
      const userIds = (n.value.match(MENTIONS_REGEX) || []).map((format) => format.match(/\d+/)[0]);

      R.pipe(
        R.map((userId) => ({
          type: 'mention',
          userId,
          data: {
            hName: 'a',
            hProperties: {
              userId,
            },
          },
          children: [{
            type: 'text',
            value: `@(${userId})`,
            userId,
          }],
        })),
        R.append(null), // to make sure there are as much mentions as text fragments
        R.zip(R.map((value) => ({ type: 'text', value }), fragments)),
        R.flatten,
        R.reject(R.isNil),
        R.forEach((f) => acc.push(f)),
      )(userIds);
    } else {
      acc.push(checkForText(n));
    }

    return acc;
  }, []);
  return node;
};

function customPlugin() {
  return (tree) => {
    visit(tree, 'paragraph', checkForText);
    visit(tree, 'heading', checkForText);
    visit(tree, 'html', (node) => {
      // UL OL LI are not parsing saparately in markdown so we need to parse them manually.
      const userIds = (node.value.match(MENTIONS_REGEX) || []).map((format) => format.match(/\d+/)[0]);
      if (userIds.length > 0) {
        const newValue = node.value.replace(MENTIONS_REGEX, (match) => {
          const userId = match.match(/\d+/)[0];
          // replacing the mention @(ID) with a span tag with the data attribute
          return `<a data-user-id=${userId}></a>`;
        });
        // eslint-disable-next-line no-param-reassign
        node.value = newValue;
      }
    });
  };
}

const Text = ({ className, children, users, enableReadMore }) => {
  const [isOpen, setOpen] = useState(!enableReadMore);

  if (!children || typeof children !== 'string') return null;

  const setHeight = (ref) => {
    if (ref && ref.offsetHeight <= 126) {
      setOpen(true);
    }
  };

  const content = formatMarkdown(children);
  // console.log('debug Text children', children);
  // console.log('debug Text content', content);

  const result = (
    <Markdown
      remarkPlugins={[customPlugin, remarkGfm]}
      rehypePlugins={[rehypeRaw]}
      className={combineClassNames('Text__Inner', className)}
      components={{
        a: ({ node, children: aChildren }) => {
          const userId = node.properties && (node.properties.userId || node.properties.dataUserId);
          const user = userId && users[userId];

          if (user) {
            return (
              <ProfilePopover user={user} key={`userId-${userId}`}>
                <span className="Text__Mention">{user.full_name}</span>
              </ProfilePopover>
            );
          }

          return <a href={node.properties.href} target="_blank" rel="noreferrer">{aChildren}</a>;
        },
        code: ({ children: codeChildren }) => {
          // console.log("debug code", codeChildren);
          return <code>{codeChildren}</code>;
        },
      }}
    >
      {content}
    </Markdown>
  );

  if (enableReadMore) {
    return (
      <div className={combineClassNames('Text', className)}>
        <div ref={setHeight} style={!isOpen ? { maxHeight: 126 } : undefined}>
          {result}
        </div>
        {!isOpen && (
          <div className="Text__ReadMore" onClick={() => setOpen(true)}>
            <Trans i18nKey="common:text_read_more" />
          </div>
        )}
      </div>
    );
  }

  return result;
};

const mapStateToProps = () => {
  const selector = usersSelector.byIds();

  return (state, props) => ({
    users: selector(state, props.children),
  });
};

export default connect(mapStateToProps)(Text);

// LOGIC FROM HERE TIL THE END OF FILE IS A PATCH, WE MUST MIGRATE TO HTML FOR
// INTERNAL STATE OF THE EDITOR

function isULRow(row) { // checks if row is unordered list (<ul>)
  return row[0] === '-' && row[1] === ' ';
}

const numbers = '0123456789';
function isListNumber(str) {
  if (str[0] === '0') {
    return false;
  }
  const nonNumber = str.split('').find((char) => {
    return numbers.indexOf(char) < 0;
  });
  return !nonNumber;
}

function getPrefixNumber(row) {
  const dotPos = row.indexOf('.');
  if (dotPos > -1 && row[dotPos + 1] === ' ') {
    const value = row.substring(0, dotPos);
    if (isListNumber(value)) {
      return value;
    }
  }
  return null;
}

function isOLRow(row, previousRow) { // ordered list row
  const prefixValue = getPrefixNumber(row);
  if (prefixValue) {
    if (previousRow) {
      const previousPrefixValue = getPrefixNumber(previousRow);
      if (previousPrefixValue) {
        const n = parseInt(prefixValue);
        const prevN = parseInt(previousPrefixValue);
        const isSequential = n === (prevN + 1);
        return isSequential;
      }
    }
    return prefixValue === '1';
  }
  return false;
}

// designed to have the appearance match the editor's
function formatMarkdown(original) {
  const converter = new showdown.Converter();
  let result = '';
  const rows = original.split('\n');

  rows.forEach((row, i) => {
    const isUL = isULRow(row);
    const isOL = isOLRow(row, rows[i - 1]);

    const isFirstRow = i === 0;
    const isLastRow = (i + 1) >= rows.length;

    // NOTE: the commented out logic fixes PD-8250 but disables quoting users
    // in code block. We need to fix that bug when we migrate to HTML from
    // markdown

    const prevRowIsUL = isFirstRow ? false : isULRow(rows[i - 1]);
    const prevRowIsOL = isFirstRow ? false : isOLRow(rows[i - 1], rows[i - 2]);

    if (isUL && !prevRowIsUL) {
      const className = prevRowIsOL ? 'otherListTypeBefore' : '';
      result += `<ul class="${className}">\n`;
    } else if (isOL && !prevRowIsOL) {
      const className = prevRowIsUL ? 'otherListTypeBefore' : '';
      result += `<ol class="${className}">\n`;
    }

    if (isUL || isOL) {
      let cutIndex = '- '.length; // for unordered lists, they begin with "- "
      if (isOL) {
        cutIndex = row.indexOf('.') + '. '.length; // strip list item numbering
      }
      let item = converter.makeHtml(row.substring(cutIndex));
      // console.log("debug formatMarkdown item converter.makeHtml", item);
      item = addDefaultHighlight(item);
      // console.log("debug formatMarkdown item post highlight", item);
      result += `<li>\n${item}\n</li>\n`;
    } else {
      result += (row + (isLastRow ? '' : '<br>\n\n'));
    }

    if (isUL && (isLastRow || !isULRow(rows[i + 1]))) {
      result += '</ul>\n';
    } else if (isOL && (isLastRow || !isOLRow(rows[i + 1], rows[i]))) {
      result += '</ol>\n';
    }
  });

  return result;
}

function isValidYup(validator, value) {
  try {
    validator.validateSync(value);
    return true;
  } catch (err) {
    if (err instanceof ValidationError) {
      return false;
    }
    throw err;
  }
}

function isValidUrl(value) {
  const urlValidator = string().required().url();
  return isValidYup(urlValidator, value);
}

function isValidEmail(value) {
  const emailValidator = string().required().email();
  return isValidYup(emailValidator, value);
}

function createAnchor(href, content) {
  // console.log("debug createAnchor href", href, "content", content);
  const a = document.createElement('a');
  a.href = href;
  a.textContent = content;
  a.target = '_blank';
  a.rel = 'noreferrer';
  return a;
}

function wrapWithAnchorTags(node) {
  // console.log("debug traverse node", node);
  if (
    node.nodeType === TEXT_NODE &&
    // no need for wrapping text in an anchor tag if we are already in one
    node.parentNode?.nodeName.toLowerCase() !== 'a'
  ) {

    const formattedNodes = node.textContent.split(' ')
      .map((chunk) => {
        let nodes;
        if (isValidUrl(chunk)) {
          nodes = [createAnchor(chunk, chunk)];
        } else if (containsEmail(chunk)) {
          nodes = getEmailWithMargins(chunk);
        } else {
          nodes = [document.createTextNode(chunk)];
        }
        return [
          ...nodes,
          document.createTextNode(' ')
        ];
      }).flat();

    formattedNodes.pop(); // strip the last space (" ") text node

    node.replaceWith(...formattedNodes);
  }
  Array.from(node.childNodes).forEach((childNode) => {
    wrapWithAnchorTags(childNode);
  });
}

// adds anchor tags for links and email addresses that were not explicitly
// set as links
function addDefaultHighlight(html) {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  wrapWithAnchorTags(doc.body);
  return doc.body.innerHTML;
}

function stripInvalidEmailCharsLeft(value) {
  let result = value;
  let stripped = '';
  while (VALID_LEFT_EMAIL_CHARS.indexOf(result[0]) < 0) {
    stripped += result[0];
    result = result.substring(1);
  }
  return [result, stripped];
}

function stripInvalidEmailCharsRight(value) {
  let result = value;
  let stripped = '';
  while (VALID_RIGHT_EMAIL_CHARS.indexOf(result[result.length - 1]) < 0) {
    stripped = (result[result.length - 1] + stripped);
    result = result.substring(0, result.length - 1);
  }
  return [result, stripped];
}

function getEmailWithMargins(value) {
  const [emailPartial, marginLeft] = stripInvalidEmailCharsLeft(value);
  const [email, marginRight] = stripInvalidEmailCharsRight(emailPartial);
  if (isValidEmail(email)) {
    return [
      marginLeft && document.createTextNode(marginLeft),
      createAnchor('mailto:' + email, email),
      marginRight && document.createTextNode(marginRight)
    ].filter(Boolean);
  }
  return [document.createTextNode(value)];
}

function containsEmail(text) {
  return (
    EMAIL_REG_EXP.test(text) &&
    // make sure text contains only one email address
    text.split('@').length === 2
  );
}
